.board-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 50px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

.board-address{
    display: flex;
}

.board-address button{
    width: 10%;
    height: 10%;
}

.board-address a{
    cursor: pointer;
}

.btn-create-route{
    background-color: #4a90e2;
    width: 50%;
    padding: 10px;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.div-create-route{
    text-align: center;
    margin: 20% 0% 20% 0px;
}