/* MyForm.css */
.form-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 50px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #4a90e2;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #357ab8;
  }
  

  .combobox-input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    
  }

  .combobox-popover{
    background-color: white;
    cursor: pointer;
    transition: 0.5s;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .combobox-popover li:hover{
    color: #F39C12;
    text-decoration: underline;
    font-size: 15px;
    
  }

  .combobox-option{
    list-style: none;
    padding-bottom: 10px;
  }


  .places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
  }